import { Routes } from "@angular/router"

export const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./tabs/tabs.routes").then((m) => m.routes),
  },
  {
    path: "legis/legis-visualizar",
    loadComponent: () => import("./viewer/viewer.page").then((m) => m.ViewerPage),
  },
  {
    path: "",
    redirectTo: "/tabs/tab1",
    pathMatch: "full",
  },
]
