/// <reference types="@angular/localize" />

import { enableProdMode, importProvidersFrom } from "@angular/core"
import { bootstrapApplication } from "@angular/platform-browser"
import { RouteReuseStrategy, provideRouter } from "@angular/router"
import { IonicRouteStrategy, provideIonicAngular } from "@ionic/angular/standalone"

import { routes } from "./app/app.routes"
import { AppComponent } from "./app/app.component"
import { environment } from "./environments/environment"
import { provideHttpClient } from "@angular/common/http"
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"

if (environment.production) {
  enableProdMode()
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(FormsModule, ReactiveFormsModule),
    provideHttpClient(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes),
    provideAnimationsAsync(),
  ],
})
